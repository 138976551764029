<script setup>
  import { useVueFlow } from '@vue-flow/core'
  import { defineEmits } from 'vue'

  const emit = defineEmits(['nodeDoubleClicked', 'nodeEdited', 'nodeRemoved']);

  const {
    onNodeDoubleClick,
    onNodeClick
  } = useVueFlow()


  const openEditWindow = (id) => {
    emit('nodeDoubleClicked', id)
  }

  onNodeDoubleClick(e => openEditWindow(e.node.id))

  // Add these new handlers
  onNodeClick(e => {
    if (e.event.target.closest('.node-burger-menu')) {
      // Ignore clicks on the burger menu
      return
    }
  })

  const handleNodeEdit = (id) => {
    openEditWindow(id)
  }

  const handleNodeRemove = (id) => {
    emit('nodeRemoved', id)
  }

  defineExpose({
    handleNodeEdit,
    handleNodeRemove
  })
</script>
