<script setup>
import { defineProps, defineEmits } from 'vue'
import { Handle, Position } from '@vue-flow/core'
import { usePipelineStore } from '@/stores/pipeline'
import NodeBurgerMenu from '@/components/NodeBurgerMenu.vue'

const props = defineProps(['id', 'label', 'data', 'type'])
const emit = defineEmits(['editNode', 'removeNode'])

const pipelineStore = usePipelineStore()
const [targetHandles, sourceHandles] = pipelineStore.handlesForTask(props.id)

const templateToclass = (template) => {
  switch (template) {
    case 'default':
      return 'bg-default'
    case 'isolated':
      return 'bg-isolated'
    default:
      return 'bg-default'
  }
}

const removeNode = () => {
  emit('removeNode', props.id)
}

const editNode = () => {
  emit('editNode', props.id)
}

</script>

<template>
  <Handle v-for="(item, index) in targetHandles"
    :key="index"
    :id="item.id"
    :position="Position.Top"
    :style="`margin-left: ${index * 10}px`"
    :class="templateToclass(item.data?.template)"
    :title="item.data?.title"
    :type="item.type"
   />

  <div class="relative">
    <NodeBurgerMenu
      @delete="removeNode"
      @edit="editNode"
      :node-type="props.type"
    />

    <div class="flex flex-col">
      <div class="text-left text-xl font-bold">{{ label }}</div>
      <div class="text-left text-sm text-gray-500">{{ data.description }}</div>
    </div>
  </div>

  <Handle v-for="(item, index) in sourceHandles"
    :key="index"
    :id="item.id"
    :position="Position.Bottom"
    :style="`margin-left: ${index * 10}px`"
    :class="templateToclass(item.data?.template)"
    :title="item.data?.title"
    :type="item.type"
   />
</template>

<style>
  .bg-default {
    background-color: black !important;
  }
  .bg-isolated {
    border-color: black !important;
    background-color: white !important;
  }
</style>
