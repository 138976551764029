// export all Vue SFC compoennts to be compiled to ESM with Vite
console.debug('Loading components')

import Flow from "../components/Flow.vue";
import * as Vue from 'vue'
import * as Pinia from 'pinia'

import { EllipsisVerticalIcon, PencilIcon, TrashIcon } from '@heroicons/vue/24/solid'

const SolidIcons = {
  EllipsisVerticalIcon,
  PencilIcon,
  TrashIcon,
}

export { Flow, Vue, Pinia, SolidIcons }
