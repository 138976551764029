<script setup>
import { computed, ref } from 'vue'
import { BezierEdge } from '@vue-flow/core'
import { SolidIcons } from '@/entrypoints/components'

const props = defineProps(['id', 'sourceX', 'sourceY', 'targetX', 'targetY', 'sourcePosition', 'targetPosition']);
const emit = defineEmits(['remove'])

const handleRemove = (event) => {
  event.stopPropagation()
  emit('remove', props.id)
}

const labelDiv = {
  width: 100,
  height: 100,
};

const labelDivX = computed(() => ((props.sourceX + props.targetX) / 2) - (labelDiv.width / 2))
const labelDivY = computed(() => ((props.sourceY + props.targetY) / 2) - (labelDiv.height / 2))

const isHovering = ref(false)

const handleMouseEnter = () => {
  isHovering.value = true
}

const handleMouseLeave = () => {
  isHovering.value = false
}

</script>


<template>
    <g @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <BezierEdge
            :source-x="sourceX"
            :source-y="sourceY"
            :target-x="targetX"
            :target-y="targetY"
            :source-position="sourcePosition"
            :target-position="targetPosition"
        />

        <foreignObject
          :x="labelDivX"
          :y="labelDivY"
          :width="labelDiv.width"
          :height="labelDiv.height"
        >
          <div class="w-full h-full flex justify-center items-center" xmlns="http://www.w3.org/1999/xhtml">
            <button
              class="rounded-full bg-primary border text-xs text-primary p-1 transition-opacity duration-200"
              :class="{ 'opacity-0': !isHovering, 'opacity-100': isHovering }"
              @click="handleRemove"
              title="Remove Connection"
            >
              <SolidIcons.TrashIcon class="size-3" />
            </button>
          </div>
        </foreignObject>
    </g>
</template>

