<script setup>
import { ref, computed } from 'vue'
import { SolidIcons } from '@/entrypoints/components'

const props = defineProps({
  nodeType: {
    type: String,
    required: true
  }
})

const isDropdownOpen = ref(false)

const toggleDropdown = () => {
  isDropdownOpen.value = !isDropdownOpen.value
}

const emit = defineEmits(['edit', 'delete'])

const handleEdit = () => {
  emit('edit')
  isDropdownOpen.value = false // Close the dropdown after edit action
}

const handleDelete = () => {
  emit('delete')
  isDropdownOpen.value = false // Close the dropdown after deletion
}

const showEditButton = computed(() => {
  // Add logic here if there are any node types that shouldn't have an edit button
  return true
})

const showDeleteButton = computed(() => {
  return props.nodeType !== 'input'
})

const stopPropagation = (event) => {
  event.stopPropagation();
}
</script>

<template>
  <div class="absolute top-0 right-0 node-burger-menu" @click="stopPropagation" @dblclick="stopPropagation">
    <!-- hamburger menu -->
    <button @click="toggleDropdown" class="border rounded p-1 text-xs"><SolidIcons.EllipsisVerticalIcon class="size-3" /></button>

    <!-- dropdown menu -->
    <div v-show="isDropdownOpen" class="absolute left-full top-0 ml-1 rounded shadow-lg bg-white ring-1 ring-black ring-opacity-5">
      <div class="flex flex-col gap-1 py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <button
          v-if="showEditButton"
          @click="handleEdit"
          class="text-xs px-2 text-left text-gray-700 hover:bg-gray-100 flex items-center gap-2"
          role="menuitem"
        >
          <SolidIcons.PencilIcon class="size-3" />
          Edit
        </button>
        <button
          v-if="showDeleteButton"
          @click="handleDelete"
          class="text-xs px-2 text-left text-gray-700 hover:bg-gray-100 flex items-center gap-2"
          role="menuitem"
        >
          <SolidIcons.TrashIcon class="size-3" />
          Delete
        </button>
      </div>
    </div>
  </div>
</template>
